
.Search {
    padding: var(--gutter-sm);
    background-color: var(--color-gray-darken);
}
.Search-form {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .Search-form {
        flex-direction: column;
        align-items: stretch;
        row-gap: var(--gutter-base);
    }
}

.Search-form .Dropdown {
    min-width: 250px;
}
.Search-trackers {
    display: flex;
    flex-direction: column;
}
.Search-trackers > button {
    border-radius: 0;
}
.Search-trackers > button:not(:first-child) {
    border-top: 1px solid var(--color-gray-darken);
}
.Search-form input {
    width: 100%;
}
.Search-progress {
    margin-top: var(--gutter-base);
}
