.AccountInfoItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--gutter-sm);
  background-color: var(--color-gray-darken);
  border-radius: var(--border-radius-base);
}

.AccountInfoItem-info {
  display: flex;
  flex-direction: column;

}

.AccountInfoItem-label {
  color: var(--color-primary-lighten);
  font-weight: var(--font-weight-bold);
}

.AccountInfoItem-value {
  color: var(--color-primary-lighten);
}
.AccountInfoItem-extra {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}