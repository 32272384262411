.TorrentLine {
    position: relative;
    border-collapse: collapse;
    width: 100%;
    height: 40px;
    color: var(--color-white);
    background-color: var(--color-gray-darken);
}
.TorrentLine td {
    z-index: 1;
    border: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
    line-height: 40px;
}
.TorrentLine-name {
    flex: 5 5 0;
}
.TorrentLine-name a {
    color: var(--color-primary-lighten);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.TorrentLine-progress {
    padding: 0 !important;
    z-index: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.TorrentLine-progress .Progress {
    width: 100%;
    flex: 1 1 0;
}
.TorrentLine .Progress-bar {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
}