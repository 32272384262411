.TorrentLine:hover {
    background-color: rgba(var(--color-rgb-primary-active), 0.3);
    cursor: pointer;
    border-radius: var(--border-radius-sm);
}
.TorrentLine-name {
    flex: 4 4 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
.TorrentLine td {
    line-height: 40px;
}