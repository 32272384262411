.FileLine {
    background-color: var(--color-gray-darken);
}

.FileLine td {
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    align-items: flex-start;
}

.FileLine-progress {
    z-index: 0;
    padding: 0 !important;
    z-index: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.FileLine.directory {
    cursor: pointer;
    color: var(--color-warning-default);
    background-color: rgba(var(--color-rgb-gray-darken), 0.6);
}

.FileLine.file {
    position: relative;
}

.FileLine-name {
    flex: 4 4 0;
}
.FileLine-download {
    flex: 2 2 0;
}
.FileLine-download a {
    display: flex;
}