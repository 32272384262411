.TorrentCard {
    position: relative;
    overflow: hidden;
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-color: rgba(var(--color-rgb-gray-darken), 0.5);
    border-radius: var(--border-radius-base);
    cursor: pointer;
    text-align: center;
}
.TorrentCard-progress {
    padding: 0 !important;
    z-index: 0;
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.TorrentCard-progress .Progress {
    
    width: 100%;
    flex: 1 1 0;
}
.TorrentCard .Progress-bar {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
}
.TorrentCard-label {
    justify-self: flex-start;
    align-self: baseline;
    color: var(--color-gray-lighten);
    font-size: var(--font-size-md);
    line-height: 30px;
}
.TorrentCard > * {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 var(--gutter-base);
}
.TorrentCard-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TorrentCard-item:not(:first-child) {
    border-top: 1px solid var(--color-gray-default);
}
.TorrentCard-name {
    border-top: none !important;
    height: 50px;
    line-height: 50px;
    background-color: rgba(var(--color-rgb-primary-default), 1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.TorrentCard-shared input {
    align-self: self-end;
}

.TorrentCard-remove {
    padding: 0;
}
.TorrentCard-remove > button {
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}