.Roles-create {
    background-color: var(--color-gray-lighten);
    padding: var(--gutter-lg);
}

.Roles-create form {
    display: flex;
    flex-direction: column;
    row-gap: var(--gutter-base);
}

.Roles-create input {
    color: var(--color-black);
}
