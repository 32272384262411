.Dropdown {
    position: relative;
    display: flex;
}

.Dropdown label {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--gutter-base);
    height: 50px;
    background-color: var(--color-primary-default);
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    border-radius: var(--border-radius-sm);
}
.Dropdown-content {
    z-index: 9999;
    margin-top: var(--gutter-sm);
    overflow: auto;
    min-width: 100%;
    max-height: 250px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--color-white);
    color: var(--color-black);
    border-radius: var(--border-radius-sm);
}