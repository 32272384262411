.Account {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 var(--gutter-lg);
  row-gap: var(--gutter-lg);
}
.Account-info {
  display: flex;
  flex-direction: column;
  row-gap: var(--gutter-base);
}

.Account-info-pay img {
  height: 30px;
}
.Account-torrent {
  display: flex;
  flex-direction: column;
  row-gap: var(--gutter-base);
}
.Account-change-password button {
  margin-top: var(--gutter-base);
}
.Account-change-password form {
  display: flex;
  flex-direction: column;
  row-gap: var(--gutter-sm);
}

.Account-rss-create {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Account-rss-flows {
  margin-top: var(--gutter-lg);
}
.Account-rss-flow {
  background-color: var(--color-gray-darken);
  overflow: hidden;
  border-radius: var(--border-radius-base);
}
.Account-rss-flow-name {
  height: 50px;
  background-color: var(--color-primary-active);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-gray-lighten);
}
.Account-rss-flow-feed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--gutter-base);
}

.Account-rss-flow-no-feed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: var(--color-warning-default);
  font-weight: var(--font-weight-bold);
}
.Account-rss-flow-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Account-rss-flow-actions button {
  border-radius: 0;
}