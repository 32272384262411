.TorrentCompleteTypeChoice {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    border: 1px solid var(--color-primary-darken);
    border-radius: var(--border-radius-base);
    overflow: hidden;
}
.TorrentCompleteTypeChoice-item {
    cursor: pointer;
    padding: 0 var(--gutter-sm);
    color: var(--color-primary-lighten);
    background-color: var(--color-gray-darken);
    flex: 1 1 0;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.TorrentCompleteTypeChoice-item:not(:first-child) {
    border-left: 1px solid var(--color-primary-darken);
    
}

.TorrentCompleteTypeChoice-item > label {
    cursor: inherit;
}
.TorrentCompleteTypeChoice-item > input {
    cursor: inherit;
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}
.TorrentCompleteTypeChoice-item.selected {
    cursor: initial;
    background-color: var(--color-primary-active);
}

