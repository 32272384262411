.TorrentHexagones {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    font-size: 15px;
    padding: 0;
}


@media (min-width:1601px) { /* <- 6-5  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 4.111%
    }
  }
  /*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
  @media (max-width: 1600px) and (min-width:1201px) { /* <- 5-4  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 5%
    }
  }
  
  @media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 6.2%
    }
  }
  
  @media (max-width: 991px) and (min-width:768px) { /* <- 3-2  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 8.1%
    }
  }
  
  @media (max-width: 767px) and (min-width:481px) { /* <- 2-1  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 12.1%
    }
  }
  
  @media (max-width: 480px) {
    .TorrentHexagones {
      padding-bottom: 0;
    }
  }