.ResultLines {
    margin-top: var(--gutter-base);
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
}
.ResultLines-sort {
    flex: 0 0 24px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-active);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    
}
.ResultLines-sort--active {
    cursor: initial;
    background-color: var(--color-success-default);
}

.ResultLines-sort--asc {
    transform: rotate(90deg);
}

.ResultLines-sort--desc {
    transform: rotate(-90deg);
}

.ResultLines thead tr th {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: var(--gutter-sm);
}

.ResultLines tbody tr {
    height: 40px;
}
.ResultLines tr:not(:first-child) {
    border-top: 1px solid var(--color-gray-default);
}
.ResultLines td , .ResultLines td a {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.ResultLines-name {
    flex: 4 4 0 !important;
}
.ResultLines-tracker {
    flex: 1.5 1.5 0 !important;
}

