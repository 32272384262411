.ResultCard {
    overflow: hidden;
    border-radius: var(--border-radius-base);
    background-color: var(--color-gray-darken);
}
.ResultCard-name {
    padding: 0 var(--gutter-sm);
    display: block;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: var(--font-size-md);
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: var(--color-primary-active);
    color: var(--color-primary-lighten);
}
.ResultCard-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--gutter-sm);
    height: 24px;
}
.ResultCard-label {
    font-size: var(--font-size-sm);
    color: var(--color-gray-default);
}
.ResultCard-value {
    font-size: var(--font-size-md);
    color: var(--color-gray-active);
}
.ResultCard-add {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    justify-content: center;
    height: 40px !important;
}