.Role {
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-lighten);
    padding: var(--gutter-base);
    color: var(--color-black);
}
.Role-name {
    color: var(--color-primary-default);
}

.Role-limit {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Role-limit > * {
    flex: 1 1 0;
}

.Role-limit-name {
    font-weight: var(--font-weight-bold);
}

.Role-limit-type {
    font-weight: var(--font-weight-bold);
}

.Role-limit input {
    color: var(--color-black);
}
.Role-limit button {
    font-weight: var(--font-weight-bold);
}

.Role input::placeholder {
    color: var(--color-gray-default);
}

.Role-limits-create {
    display: flex;
    flex-direction: row;
}

.Role-limits-create > * {
    flex: 1 1 0;
}