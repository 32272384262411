.TorrentAdd {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    row-gap: var(--gutter-lg);
}
.TorrentAdd-forms {
    display: flex;
    flex-direction: row;
    column-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}

@media screen and (max-width: 768px) {
    .TorrentAdd-forms {
        flex-direction: column;
    }
}
.TorrentAdd .Card {
    flex: 1 1 0;
}

.TorrentAdd-url, .TorrentAdd-file {
    
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TorrentAdd-file-container {
    position: relative;
    flex: 1 1 100%;
}
.TorrentAdd-file-container > button {
    z-index: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.TorrentAdd-file > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.TorrentAdd-file-container > button {
    background-color: var(--color-primary-active);
}
.TorrentAdd-file-container > input {
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}