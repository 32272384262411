.TorrentHexagone {
    position: relative;
    color: var(--color-white);
    visibility: hidden;
    outline: 1px solid transparent;
}
.TorrentHexagone::after {
    content: '';
    display: block;
    padding-bottom: 86.602%;
}
.TorrentHexagone-hexagone {
    position: absolute;
    width:96%;
    padding-bottom: 110.851%; /* =  width / sin(60) */
    margin: 0 2%;
    overflow: hidden;
    visibility: hidden;
    outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
    -webkit-transform: rotate3d(0,0,1,-60deg) skewY(30deg);
    -ms-transform: rotate3d(0,0,1,-60deg) skewY(30deg);
    transform: rotate3d(0,0,1,-60deg) skewY(30deg);
    will-change: auto;
}

.TorrentHexagone-hexagone * {
    visibility: visible;
    outline: 1px solid transparent;
    will-change: auto;
}

.TorrentHexagone-container {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    color: var(--color-white);
    overflow: hidden;
    -webkit-transform: skewY(-30deg) rotate3d(0,0,1,60deg);
    -ms-transform: skewY(-30deg) rotate3d(0,0,1,60deg);
    transform: skewY(-30deg) rotate3d(0,0,1,60deg);
    will-change: auto;
}
.TorrentHexagone-container::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--color-rgb-primary-default), 0.5);
    content: '';
}

.TorrentHexagone-image {
    opacity: 0.5;
    position: absolute;
    left: -100%;
    right: -100%;
    width: auto;
    height: 100%;
    margin: 0 auto;
    -webkit-transform: rotate3d(0,0,0,0deg);
    -ms-transform: rotate3d(0,0,0,0deg);
    transform: rotate3d(0,0,0,0deg);
    will-change: auto;
}
.TorrentHexagone-top, .TorrentHexagone-bottom {
    z-index: 1;
    width: 100%;
    position: absolute;
    color: var(--color-white);
    background-color: rgba(var(--color-rgb-primary-default), 0.8);
    -webkit-transition:  -webkit-transform 200ms ease-in-out, opacity 200ms ease-out;
    transition:          transform 200ms ease-in-out, opacity 200ms ease-out;
    opacity: 0;
    will-change: auto;
}
.TorrentHexagone-top {
    top: 0;
    bottom: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    font-size: var(--font-size-base);
    z-index: 1;
    box-shadow: 4px 3px 6px var(--color-primary-lighten);
    background-color: var(--color-primary-active);
    font-weight: bold;
    -webkit-transform:translate3d(0,-100%,0);
    -ms-transform:translate3d(0,-100%,0);
    transform:translate3d(0,-100%,0);
    will-change: auto;
}
.TorrentHexagone-top::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.TorrentHexagone-bottom {
    top: 75%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-transform:translate3d(0,100%,0);
    -ms-transform:translate3d(0,100%,0);
    transform:translate3d(0,100%,0);
    will-change: auto;
}

.TorrentHexagone-info {
    z-index: 1;
    padding: var(--gutter-sm);
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.TorrentHexagone-info::before {
  position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: '';
	margin: auto 0;
	height: 0;
	background: linear-gradient(to bottom, rgba(var(--color-rgb-primary-active), 0.8), rgba(var(--color-rgb-primary-darken), 0.8) 50%, rgba(var(--color-rgb-primary-default), 0.8) 100%);
	transition: height 100ms ease-out;
  will-change: auto;
}

.TorrentHexagone-info-top {
  color: var(--color-primary-lighten);
  z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.TorrentHexagone-info-center {
    z-index: 1;
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.TorrentHexagone-info-name {
  color: var(--color-primary-lighten);
  font-size: var(--font-size-base);
	word-break: break-all;
	width: 96%;
	margin: 0 auto;
	font-weight: var(--font-weight-bold);
}
.TorrentHexagone-info-bottom {
  color: var(--color-primary-lighten);
  z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.TorrentHexagone-info-ratio--success {
  color: var(--color-success-default);
}
.TorrentHexagone-info-ratio--primary {
  color: var(--color-primary-default);
}
.TorrentHexagone-info-ratio--warning {
  color: var(--color-warning-default);
}
.TorrentHexagone-info-ratio--danger {
  color: var(--color-danger-default);
}

/*
    HOVER EFFECT
*/
.TorrentHexagone-container:hover .TorrentHexagone-info::before {
	height: 100%;
}

.TorrentHexagone-container:hover .TorrentHexagone-top, .TorrentHexagone-container:focus .TorrentHexagone-top,
.TorrentHexagone-container:hover .TorrentHexagone-bottom, .TorrentHexagone-container:focus .TorrentHexagone-bottom { 
    -webkit-transform:translate3d(0,0,0);
    -ms-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0);
    opacity: 1;
}
.TorrentHexagone-descriptif, .TorrentHexagone-not-tracker {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	color: var(--color-white);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: var(--gutter-sm);
}
.TorrentHexagone-descriptif:hover, .TorrentHexagone-descriptif:active, .TorrentHexagone-descriptif:active {
	color: var(--color-white);
}
.TorrentHexagone-privacy {
    height: auto;
	position: absolute;
	top: 0;
	bottom: 50%;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	border: 0;
}

.TorrentHexagone-remove {
    height: auto;
	position: absolute;
	top: 50%;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	border: 0;
	font-size: var(--font-size-base);
	color: var(--color-danger-default);
	background-color: transparent;
}


@media (min-width:1601px) { /* <- 6-5  hexagons per row */
    .TorrentHexagone {
      width: 16.666%; /* = 100 / 6 */
    }
    .TorrentHexagone:nth-child(11n+7){ /* first hexagon of even rows */
      margin-left:8.333%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  /*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
  @media (max-width: 1600px) and (min-width:1201px) { /* <- 5-4  hexagons per row */
    .TorrentHexagone {
      width: 20%; /* = 100 / 5 */
    }
    .TorrentHexagone:nth-child(9n+6){ /* first hexagon of even rows */
      margin-left:10%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
    .TorrentHexagone {
      width: 25%; /* = 100 / 4 */
    }
    .TorrentHexagone:nth-child(7n+5){ /* first hexagon of even rows */
      margin-left:12.5%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 991px) and (min-width:768px) { /* <- 3-2  hexagons per row */
    .TorrentHexagone {
      width: 33.333%; /* = 100 / 3 */
    }
    .TorrentHexagone:nth-child(5n+4){ /* first hexagon of even rows */
      margin-left:16.666%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 767px) and (min-width:481px) { /* <- 2-1  hexagons per row */
    .TorrentHexagone {
      width: 50%; /* = 100 / 3 */
    }
    .TorrentHexagone:nth-child(3n+3){ /* first hexagon of even rows */
      margin-left: 25%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 480px) {
    .TorrentHexagone {
      width: 100%;
      margin-bottom: 25%;
    }
  }