.Progress {
    width: 100%;
}

.Progress label {
    color: var(--color-gray-lighten);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--gutter-base);
}

.Progress-bar {
    
    overflow: hidden;
    border-radius: var(--border-radius-base);
    background-color: var(--color-gray-default);
    position: relative;
    height: 30px;
    text-align: center;
}

.Progress-bar-value {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    animation: progress-bar-stripes 2s linear infinite;
    background-image: -webkit-linear-gradient(45deg,rgba(var(--color-rgb-white),.25) 25%,transparent 25%,transparent 50%,rgba(var(--color-rgb-white),.25) 50%,rgba(var(--color-rgb-white),.25) 75%,transparent 75%,transparent);
    background-size: 40px 40px;
    transition: width 200ms ease-out;
    
}

.Progress-bar-value-success {
    background-color: var(--color-success-default);
}

.Progress-bar-value-warning {
    background-color: var(--color-warning-default);
}

.Progress-bar-value-danger {
    background-color: var(--color-danger-default);
}

.Progress-bar-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
}

@keyframes progress-bar-stripes {
    from  { background-position: 40px 0; }
    to    { background-position: 0 0; }
  }