.ResultCards {
    padding: var(--gutter-base) 0;
    display: grid;
    column-gap: var(--gutter-base);
    row-gap: var(--gutter-base);
}
@media (min-width:1601px) { /* <- 6-5  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  /*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
  @media (max-width: 1600px) and (min-width:1201px) { /* <- 5-4  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 991px) and (min-width:768px) { /* <- 3-2  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 767px) { /* <- 2-1  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  