.TorrentLines {
    background-color: var(--color-gray-default);
    border-radius: var(--border-radius-base);
    color: var(--color-white);
    border-collapse: collapse;
    overflow: hidden;
}
.TorrentLines th {
    height: 50px;
    padding: 0 var(--gutter-base);
    text-align: left;
}

.TorrentLines-name {
    flex: 5 5 0;
}