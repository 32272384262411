.TorrentDetail {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    grid-row-gap: var(--gutter-base);
    row-gap: var(--gutter-base);
}
.TorrentDetail-archive {
  margin-bottom: var(--gutter-lg);
}
.TorrentDetail-path {
    cursor: pointer;
    margin-bottom: var(--gutter-base);
}

.TorrentDetail-name {
    flex: 4 4 0;
}

.TorrentDetail-download {
    flex: 2 2 0;
}

.TorrentDetail-cards {
    display: grid;
    column-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}

@media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
  .TorrentDetail-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 991px) { /* <- 3-2  hexagons per row */
  .TorrentDetail-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
