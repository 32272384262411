.FileCard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-color: rgba(var(--color-rgb-gray-darken), 0.5);
    border-radius: var(--border-radius-base);
    cursor: pointer;
    text-align: center;
}
.FileCard-label {
    justify-self: flex-start;
    align-self: baseline;
    color: var(--color-gray-lighten);
    font-size: var(--font-size-md);
    line-height: 30px;
}
.FileCard > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 var(--gutter-base);
}
.FileCard-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FileCard-item:not(:first-child) {
    border-top: 1px solid var(--color-gray-default);
}
.FileCard-name {
    border-top: none !important;
    height: 50px;
    line-height: 50px;
    background-color: rgba(var(--color-rgb-primary-default), 1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.FileCard-shared input {
    align-self: self-end;
}

.FileCard-download {
    height: 50px;
    padding: 0;
}
.FileCard-download > a {
    display: block;
    height: 100%;
}
.FileCard-download > a > button {
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}