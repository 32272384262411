:root {
  
  --border-radius-sm: 3px;
  --border-radius-base: 5px;
  --border-radius-lg: 8px;

  --button-height-base: 50px;

  --color-rgb-white: 200, 255, 255;
  --color-rgb-gray-default: 100, 130, 180;
  --color-rgb-gray-lighten: 150, 200, 230;
  --color-rgb-gray-active: 50, 100, 150;
  --color-rgb-gray-darken: 30, 50, 80;
  --color-rgb-black: 0, 0, 0;

  --color-rgb-primary-default: 80, 120, 255;
  --color-rgb-primary-lighten: 180, 200, 255;
  --color-rgb-primary-darken: 10, 30, 80;
  --color-rgb-primary-active: 50, 150, 255;

  
  --color-rgb-success-default: 50, 200, 0;
  --color-rgb-success-lighten: 180, 255, 180;
  --color-rgb-success-darken: 50, 100, 50;

  --color-rgb-warning-default: 200, 100, 0;
  --color-rgb-warning-lighten: 255, 200, 100;
  --color-rgb-warning-darken: 100, 50, 0;

  --color-rgb-danger-default: 200, 50, 50;
  --color-rgb-danger-lighten: 255, 180, 180;
  --color-rgb-danger-darken: 100, 50, 50;

  --color-primary-default: rgb(var(--color-rgb-primary-default));
  --color-primary-lighten: rgb(var(--color-rgb-primary-lighten));
  --color-primary-darken: rgb(var(--color-rgb-primary-darken));
  --color-primary-active: rgb(var(--color-rgb-primary-active));

  --color-success-default: rgb(var(--color-rgb-success-default));
  --color-success-lighten: rgb(var(--color-rgb-success-lighten));
  --color-success-darken: rgb(var(--color-rgb-success-darken));

  --color-warning-default: rgb(var(--color-rgb-warning-default)); 
  --color-warning-lighten: rgb(var(--color-rgb-warning-lighten));
  --color-warning-darken: rgb(var(--color-rgb-warning-darken));

  --color-danger-default: rgb(var(--color-rgb-danger-default)); 
  --color-danger-lighten: rgb(var(--color-rgb-danger-lighten));
  --color-danger-darken: rgb(var(--color-rgb-danger-darken));

  --color-white: rgb(var(--color-rgb-white));
  --color-gray-default: rgb(var(--color-rgb-gray-default));
  --color-gray-lighten: rgb(var(--color-rgb-gray-lighten));
  --color-gray-active: rgb(var(--color-rgb-gray-active));
 	--color-gray-darken: rgb(var(--color-rgb-gray-darken));
  --color-black: rgb(var(--color-rgb-black));

  
  --gutter-sm: 8px;
  --gutter-base: 16px;
  --gutter-lg: 24px;
  --gutter-xl: 32px;
  --gutter-2xl: 64px;
  --gutter-3xl: 128px;

  --header-desktop-height: 70px;

  --heading-color: var(--color-gray-lighten);
  --heading-font-weight: var(--font-weight-bold);
  --heading-h1-font-size: 36px;
  --heading-h2-font-size: 30px;
  --heading-h3-font-size: 24px;
  --heading-h4-font-size: 20px;
  --heading-h5-font-size: 18px;
  --heading-h6-font-size: 16px;

  --font-size-xs: 8px;
  --font-size-sm: 10px;
  --font-size-md: 12px;
  --font-size-base: 14px;
  --font-size-lg: 16px;

  --font-weight-light: 100;
  --font-weight-medium: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 600;

  --footer-desktop-height: 40px;

  --grid-width-xs: 540px;
  --grid-width-sm: 740px;
  --grid-width-md: 940px;
  --grid-width-lg: 1140px;
  --grid-width-xl: 1324px;

}

[data-theme="dark"] {
  --color-rgb-primary-default: 70, 80, 200;
  --color-rgb-primary-lighten: 30, 50, 80;
  --color-rgb-primary-darken: 180, 220, 255;
  --color-dark-rgb-primary-active: 80, 120, 150;

  --color-rgb-white: 0, 0, 0;
  --color-rgb-gray-default: 150, 150, 170;
  --color-rgb-gray-lighten: 30, 30, 50;
  --color-rgb-gray-active: 100, 100, 150;
  --color-rgb-gray-darken: 200, 200, 230;
  --color-rgb-black: 255, 255, 255;
}

* {
  outline: none;
}
html {
	position: relative;
  display: flex;
  min-height: 100%;
}
body {
  max-width: 100%;
	background: var(--color-black);
  color: var(--color-white);
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
  font-weight: var(--heading-font-weight);
  margin: 0;
}

p {
  margin: 0;
}

input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="url"], input[type="file"] {
  padding: 0 var(--gutter-sm);
  width: 100%;
  color: var(--color-primary-lighten);
  -webkit-text-fill-color: var(--color-primary-lighten);
  height: 50px;
  outline: 0;
  box-shadow: none;
  border-radius: 0;
  border-width: 0 0 2px;
  border-color: var(--color-primary-darken);
  background: none;
  font-weight: var(--font-weight-bold);
}

input::placeholder {
	color: var(--color-gray-active) !important;
  -webkit-text-fill-color: var(--color-gray-active) !important;
}

input:hover, input:focus, input:active {
	box-shadow: none;
	border-color: var(--color-primary-active);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  color: var(--color-white) !important;
    transition: background-color 999999999999s ease-in-out 0s;
    -webkit-box-shadow: none;
}

input[type="checkbox"] {
  display: grid;
  place-content: center;
  border: 0;
  border-radius: var(--border-radius-sm);
  padding: 0;
  margin: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-primary-darken);
  appearance: none;
  font: inherit;
  color: var(--color-success-default);
  transform: translateY(-0.075em);
}
input[type="checkbox"]::before {
  content: "";
  width: 20px;
  height: 20px;
  transform: scale(0);
  transition: 100ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--color-success-default);
  transform-origin: center;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: none;
  outline-offset: 0;
}
input[type="checkbox"]:focus::before {
  outline: none;
  outline-offset: 0;
}


select {
  cursor: pointer;
  height: 50px;
  color: var(--color-white);
  background-color: var(--color-primary-default);
  padding: 0 var(--gutter-base);
  border: none;
  border-radius: var(--border-radius-base);
}
select.block {
  width: 100%;
  display: block;
}

select.warning {
  background-color: var(--color-warning-default);
}

select.sm {
  height: 24px;
  padding: 0 var(--gutter-sm);
}

a {
  cursor: pointer;
  text-decoration: none;
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-default);
}

a:hover {
  color: var(--color-primary-lighten);
}

a.block {
  display: block;
  width: 100%;
}

table {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
table > thead {
  background-color: var(--color-primary-default);
}

table > thead, table > tbody {
  display: flex;
  flex-direction: column;
}
table tr {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--gutter-base);
}
table tr th, table tr td {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 0 var(--gutter-base);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

table tr th {
  height: 50px;
  font-weight: var(--font-weight-bold);
}

table tbody tr {
  height: 46px;
  line-height: 46px;
  background-color: var(--color-gray-darken);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.wallpaper {
	background-size: cover;
	background-position: 100%;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-image: url('assets/images/wallpaper.jpg');
	background-repeat: no-repeat;
  filter: blur(4px);
}

#root, .App {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.App-main > * {
	flex: 1 1 auto;
}

.App-main {
  display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
	margin-top: var(--header-desktop-height);
	margin-bottom: var(--footer-desktop-height);
	padding: var(--gutter-lg) 0;
}

.App-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: var(--footer-desktop-height);
  z-index: 1;
  background-color: rgba(var(--color-rgb-gray-darken, 0.8), 0.9);
  color: var(--color-primary-lighten);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}


@media screen and (max-width: 768px) {
  .App {
  	padding: 0;
  }
  .container-full {
  	margin-top: 0 !important;
  	margin-bottom: 0 !important;
  }
  .container, .container-nostyle {
  	width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container, .container-nostyle {
  	width: var(--grid-width-sm);
  }
}

@media screen and (max-width: 992px) {
  .container, .container-nostyle {
  	width: var(--grid-width-md);
  }
}

@media screen and (max-width: 1200px) {
  .container, .container-nostyle {
  	width: var(--grid-width-lg);
  }
}

.container {
	background-color: rgba(var(--color-rgb-black), 0.5);
	border-radius: var(--border-radius-sm);
	padding: var(--gutter-base);
  width: var(--grid-width-xl);
  margin: 0 auto;
}
.container-full {
	padding: var(--gutter-base);
	margin-top: calc(var(--gutter-base) * -1);
	margin-bottom: calc(var(--gutter-base) * -1);
	background-color: rgba(var(--color-rgb-black), 0.5);
}
.container-nostyle {
  width: var(--grid-width-xl);
	background-color: transparent ! important;
	padding: 0 !important;
	border-radius: 0 !important;
	margin: 0 auto !important;
}
.container--reverse {
	background-color: rgba(var(--color-rgb-white), 0.5) !important;
}

.grid-xs {
  width: 100%;
  max-width: var(--grid-width-xs);
}
.grid-sm {
  width: 100%;
  max-width: var(--grid-width-sm);
}
.grid-md {
  width: 100%;
  max-width: var(--grid-width-md);
}
.grid-lg {
  width: 100%;
  max-width: var(--grid-width-lg);
}
.grid-xl {
  width: 100%;
  max-width: var(--grid-width-xl);
}

button {
  cursor: pointer;
  height: var(--button-height-base);
  border: none;
  border-radius: var(--border-radius-base);
  background-color: var(--color-primary-default);
  color: var(--color-white);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  padding: 0 var(--gutter-base);
}

button:hover {
  background-color: var(--color-primary-lighten);
}

button.block {
  width: 100%;
}

button.danger {
  background-color: var(--color-danger-default);
}
button.warning {
  background-color: var(--color-warning-default);
}
button.success {
  background-color: var(--color-success-default);
}

button.sm {
  height: 24px;
  font-size: var(--font-size-sm);
}

.error {
  background-color: var(--color-danger-default);
  height: 50px;
  border-radius: var(--border-radius-base);
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#notifications {
  z-index: 1000;
  position: fixed;
  bottom: var(--gutter-sm);
  right: var(--gutter-sm);
  display: flex;
  flex-direction: column;
  row-gap: var(--gutter-sm);
}