@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
body, button, ::placeholder {
  font-size: var(--font-size-base);
  margin: 0;
  font-family: "Varela Round", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  
  --border-radius-sm: 3px;
  --border-radius-base: 5px;
  --border-radius-lg: 8px;

  --button-height-base: 50px;

  --color-rgb-white: 200, 255, 255;
  --color-rgb-gray-default: 100, 130, 180;
  --color-rgb-gray-lighten: 150, 200, 230;
  --color-rgb-gray-active: 50, 100, 150;
  --color-rgb-gray-darken: 30, 50, 80;
  --color-rgb-black: 0, 0, 0;

  --color-rgb-primary-default: 80, 120, 255;
  --color-rgb-primary-lighten: 180, 200, 255;
  --color-rgb-primary-darken: 10, 30, 80;
  --color-rgb-primary-active: 50, 150, 255;

  
  --color-rgb-success-default: 50, 200, 0;
  --color-rgb-success-lighten: 180, 255, 180;
  --color-rgb-success-darken: 50, 100, 50;

  --color-rgb-warning-default: 200, 100, 0;
  --color-rgb-warning-lighten: 255, 200, 100;
  --color-rgb-warning-darken: 100, 50, 0;

  --color-rgb-danger-default: 200, 50, 50;
  --color-rgb-danger-lighten: 255, 180, 180;
  --color-rgb-danger-darken: 100, 50, 50;

  --color-primary-default: rgb(var(--color-rgb-primary-default));
  --color-primary-lighten: rgb(var(--color-rgb-primary-lighten));
  --color-primary-darken: rgb(var(--color-rgb-primary-darken));
  --color-primary-active: rgb(var(--color-rgb-primary-active));

  --color-success-default: rgb(var(--color-rgb-success-default));
  --color-success-lighten: rgb(var(--color-rgb-success-lighten));
  --color-success-darken: rgb(var(--color-rgb-success-darken));

  --color-warning-default: rgb(var(--color-rgb-warning-default)); 
  --color-warning-lighten: rgb(var(--color-rgb-warning-lighten));
  --color-warning-darken: rgb(var(--color-rgb-warning-darken));

  --color-danger-default: rgb(var(--color-rgb-danger-default)); 
  --color-danger-lighten: rgb(var(--color-rgb-danger-lighten));
  --color-danger-darken: rgb(var(--color-rgb-danger-darken));

  --color-white: rgb(var(--color-rgb-white));
  --color-gray-default: rgb(var(--color-rgb-gray-default));
  --color-gray-lighten: rgb(var(--color-rgb-gray-lighten));
  --color-gray-active: rgb(var(--color-rgb-gray-active));
 	--color-gray-darken: rgb(var(--color-rgb-gray-darken));
  --color-black: rgb(var(--color-rgb-black));

  
  --gutter-sm: 8px;
  --gutter-base: 16px;
  --gutter-lg: 24px;
  --gutter-xl: 32px;
  --gutter-2xl: 64px;
  --gutter-3xl: 128px;

  --header-desktop-height: 70px;

  --heading-color: var(--color-gray-lighten);
  --heading-font-weight: var(--font-weight-bold);
  --heading-h1-font-size: 36px;
  --heading-h2-font-size: 30px;
  --heading-h3-font-size: 24px;
  --heading-h4-font-size: 20px;
  --heading-h5-font-size: 18px;
  --heading-h6-font-size: 16px;

  --font-size-xs: 8px;
  --font-size-sm: 10px;
  --font-size-md: 12px;
  --font-size-base: 14px;
  --font-size-lg: 16px;

  --font-weight-light: 100;
  --font-weight-medium: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 600;

  --footer-desktop-height: 40px;

  --grid-width-xs: 540px;
  --grid-width-sm: 740px;
  --grid-width-md: 940px;
  --grid-width-lg: 1140px;
  --grid-width-xl: 1324px;

}

[data-theme="dark"] {
  --color-rgb-primary-default: 70, 80, 200;
  --color-rgb-primary-lighten: 30, 50, 80;
  --color-rgb-primary-darken: 180, 220, 255;
  --color-dark-rgb-primary-active: 80, 120, 150;

  --color-rgb-white: 0, 0, 0;
  --color-rgb-gray-default: 150, 150, 170;
  --color-rgb-gray-lighten: 30, 30, 50;
  --color-rgb-gray-active: 100, 100, 150;
  --color-rgb-gray-darken: 200, 200, 230;
  --color-rgb-black: 255, 255, 255;
}

* {
  outline: none;
}
html {
	position: relative;
  display: flex;
  min-height: 100%;
}
body {
  max-width: 100%;
	background: var(--color-black);
  color: var(--color-white);
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
  font-weight: var(--heading-font-weight);
  margin: 0;
}

p {
  margin: 0;
}

input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="url"], input[type="file"] {
  padding: 0 var(--gutter-sm);
  width: 100%;
  color: var(--color-primary-lighten);
  -webkit-text-fill-color: var(--color-primary-lighten);
  height: 50px;
  outline: 0;
  box-shadow: none;
  border-radius: 0;
  border-width: 0 0 2px;
  border-color: var(--color-primary-darken);
  background: none;
  font-weight: var(--font-weight-bold);
}

input::placeholder {
	color: var(--color-gray-active) !important;
  -webkit-text-fill-color: var(--color-gray-active) !important;
}

input:hover, input:focus, input:active {
	box-shadow: none;
	border-color: var(--color-primary-active);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  color: var(--color-white) !important;
    -webkit-transition: background-color 999999999999s ease-in-out 0s;
    transition: background-color 999999999999s ease-in-out 0s;
    -webkit-box-shadow: none;
}

input[type="checkbox"] {
  display: grid;
  place-content: center;
  border: 0;
  border-radius: var(--border-radius-sm);
  padding: 0;
  margin: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-primary-darken);
  appearance: none;
  font: inherit;
  color: var(--color-success-default);
  transform: translateY(-0.075em);
}
input[type="checkbox"]::before {
  content: "";
  width: 20px;
  height: 20px;
  transform: scale(0);
  transition: 100ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--color-success-default);
  transform-origin: center;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: none;
  outline-offset: 0;
}
input[type="checkbox"]:focus::before {
  outline: none;
  outline-offset: 0;
}


select {
  cursor: pointer;
  height: 50px;
  color: var(--color-white);
  background-color: var(--color-primary-default);
  padding: 0 var(--gutter-base);
  border: none;
  border-radius: var(--border-radius-base);
}
select.block {
  width: 100%;
  display: block;
}

select.warning {
  background-color: var(--color-warning-default);
}

select.sm {
  height: 24px;
  padding: 0 var(--gutter-sm);
}

a {
  cursor: pointer;
  text-decoration: none;
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-default);
}

a:hover {
  color: var(--color-primary-lighten);
}

a.block {
  display: block;
  width: 100%;
}

table {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
table > thead {
  background-color: var(--color-primary-default);
}

table > thead, table > tbody {
  display: flex;
  flex-direction: column;
}
table tr {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: var(--gutter-base);
  column-gap: var(--gutter-base);
}
table tr th, table tr td {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 0 var(--gutter-base);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

table tr th {
  height: 50px;
  font-weight: var(--font-weight-bold);
}

table tbody tr {
  height: 46px;
  line-height: 46px;
  background-color: var(--color-gray-darken);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.wallpaper {
	background-size: cover;
	background-position: 100%;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-image: url(/static/media/wallpaper.0f2976ea.jpg);
	background-repeat: no-repeat;
  filter: blur(4px);
}

#root, .App {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.App-main > * {
	flex: 1 1 auto;
}

.App-main {
  display: flex;
	flex-direction: column;
	flex: 1 1;
	position: relative;
	margin-top: var(--header-desktop-height);
	margin-bottom: var(--footer-desktop-height);
	padding: var(--gutter-lg) 0;
}

.App-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: var(--footer-desktop-height);
  z-index: 1;
  background-color: rgba(var(--color-rgb-gray-darken, 0.8), 0.9);
  color: var(--color-primary-lighten);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}


@media screen and (max-width: 768px) {
  .App {
  	padding: 0;
  }
  .container-full {
  	margin-top: 0 !important;
  	margin-bottom: 0 !important;
  }
  .container, .container-nostyle {
  	width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container, .container-nostyle {
  	width: var(--grid-width-sm);
  }
}

@media screen and (max-width: 992px) {
  .container, .container-nostyle {
  	width: var(--grid-width-md);
  }
}

@media screen and (max-width: 1200px) {
  .container, .container-nostyle {
  	width: var(--grid-width-lg);
  }
}

.container {
	background-color: rgba(var(--color-rgb-black), 0.5);
	border-radius: var(--border-radius-sm);
	padding: var(--gutter-base);
  width: var(--grid-width-xl);
  margin: 0 auto;
}
.container-full {
	padding: var(--gutter-base);
	margin-top: calc(var(--gutter-base) * -1);
	margin-bottom: calc(var(--gutter-base) * -1);
	background-color: rgba(var(--color-rgb-black), 0.5);
}
.container-nostyle {
  width: var(--grid-width-xl);
	background-color: transparent ! important;
	padding: 0 !important;
	border-radius: 0 !important;
	margin: 0 auto !important;
}
.container--reverse {
	background-color: rgba(var(--color-rgb-white), 0.5) !important;
}

.grid-xs {
  width: 100%;
  max-width: var(--grid-width-xs);
}
.grid-sm {
  width: 100%;
  max-width: var(--grid-width-sm);
}
.grid-md {
  width: 100%;
  max-width: var(--grid-width-md);
}
.grid-lg {
  width: 100%;
  max-width: var(--grid-width-lg);
}
.grid-xl {
  width: 100%;
  max-width: var(--grid-width-xl);
}

button {
  cursor: pointer;
  height: var(--button-height-base);
  border: none;
  border-radius: var(--border-radius-base);
  background-color: var(--color-primary-default);
  color: var(--color-white);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  padding: 0 var(--gutter-base);
}

button:hover {
  background-color: var(--color-primary-lighten);
}

button.block {
  width: 100%;
}

button.danger {
  background-color: var(--color-danger-default);
}
button.warning {
  background-color: var(--color-warning-default);
}
button.success {
  background-color: var(--color-success-default);
}

button.sm {
  height: 24px;
  font-size: var(--font-size-sm);
}

.error {
  background-color: var(--color-danger-default);
  height: 50px;
  border-radius: var(--border-radius-base);
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#notifications {
  z-index: 1000;
  position: fixed;
  bottom: var(--gutter-sm);
  right: var(--gutter-sm);
  display: flex;
  flex-direction: column;
  grid-row-gap: var(--gutter-sm);
  row-gap: var(--gutter-sm);
}

.Header {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(var(--color-rgb-black), 0.9);
    height: var(--header-desktop-height);
    font-size: var(--heading-h5-font-size);
    color: white;
}
.Header > div {
    padding: 0 var(--gutter-lg);
    max-width: var(--grid-width-xl);
      margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: var(--gutter-lg);
    column-gap: var(--gutter-lg);
}

.Header-logo {
    cursor: pointer;
    height: calc(var(--header-desktop-height) - calc(2 * var(--gutter-sm)));
}
  
.Header-auth, .Header-not-auth {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Header-auth {
    justify-content: space-between;
}
.Header-not-auth {
    justify-content: flex-end;
}
.Header-auth-links, .Header-auth-user, .Header-not-auth-links {
    display: flex;
    flex-direction: row;
    grid-column-gap: var(--gutter-base);
    column-gap: var(--gutter-base);
    list-style-type:none;
    padding:0px;
    margin:0px;
}
  
.Header-auth-links li, .Header-auth-user li, .Header-not-auth-links li  {
    list-style:none;
    background-image:none;
    background-repeat:none;
    background-position:0; 
}
  
.Header-auth-links li a, .Header-auth-user li a, .Header-not-auth-links li a {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-primary-lighten);
    font-size: var(--heading-h6-font-size);
    font-weight: var(--font-weight-bold);
}

.Header-auth-links li a.active, .Header-auth-user li a.active, .Header-not-auth-links li a.active {
    color: var(--color-primary-active);
    font-weight: var(--font-weight-bold);
}
.Header-auth-links li a:hover, .Header-auth-user li a:hover, .Header-not-auth-links li a:hover {
    color: var(--color-primary-default);
}

.Header-menu-container {
    justify-content: space-between !important;
}
.Header-menu-backdrop {
    z-index: 999998;
    cursor: pointer;
    opacity: 0;
    position: fixed;
    top: var(--header-desktop-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--color-rgb-gray-darken), 0.8);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms linear, visibility 200ms linear;
}
.Header-menu-backdrop--visible {
    opacity: 1;
    visibility: visible;
}
.Header-menu-panel {
    z-index: 999999;
    position: fixed;
    top: var(--header-desktop-height);
    bottom: 0;
    left: 0;
    width: 300px;
    background-color: var(--color-primary-darken) !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between !important;
    padding: var(--gutter-lg);
    will-change: auto;
    transform: translate3d(-100%, 0, 0);
    transition: transform 200ms ease-out;
}

.Header-menu-panel--open {
    transform: translate3d(0, 0, 0);
}

.Header-menu-panel > ul {
    flex-direction: column !important;
    grid-row-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}

.Header-menu-icon {
    cursor: pointer;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--gutter-sm);
}
.Header-menu-icon {
    color: var(--color-primary-lighten);
}

.Header-menu-icon--visible {
    display: flex;
}

.Header-home {
    padding: 0 var(--gutter-base);
    display: flex;
    align-items: center;
    color: var(--color-primary-default) !important;
    text-decoration: none;
    height: 100%;
}
.Connection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-self: center;
  border-radius: var(--border-radius-base);
}
.Connection .Card {
  margin: 0 var(--gutter-base);
}
.Connection-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Connection-form > input {
  width: auto;
  margin-top: var(--gutter-base);
}
.Connection-form > button {
  margin-top: var(--gutter-2xl);
}
.Card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--gutter-lg);
    color: var(--color-white);
    background-color: rgba(var(--color-rgb-primary-darken), 0.8);
    overflow: visible;
    border-radius: var(--border-radius-sm);
}
.Card--transparent {
    background-color: transparent;
}
.Card-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    grid-column-gap: var(--gutter-base);
    column-gap: var(--gutter-base);
}

@media screen and (max-width: 768px) {
    
    .Card-header {
        flex-direction: column;
    }

    .Card-actions {
        align-self: flex-end;
    }
}
.Card-header > label {
    display: block;
    width: 100%;
    margin-bottom: var(--gutter-lg);
    font-size: var(--heading-h4-font-size);
    font-weight: var(--font-weight-bold);
    flex: 1 1;
    word-break: break-all;
}
.Card-actions {
    flex: 0 0 auto;
}
.Card-content {
    overflow: visible;
}
.Account {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 var(--gutter-lg);
  grid-row-gap: var(--gutter-lg);
  row-gap: var(--gutter-lg);
}
.Account-info {
  display: flex;
  flex-direction: column;
  grid-row-gap: var(--gutter-base);
  row-gap: var(--gutter-base);
}

.Account-info-pay img {
  height: 30px;
}
.Account-torrent {
  display: flex;
  flex-direction: column;
  grid-row-gap: var(--gutter-base);
  row-gap: var(--gutter-base);
}
.Account-change-password button {
  margin-top: var(--gutter-base);
}
.Account-change-password form {
  display: flex;
  flex-direction: column;
  grid-row-gap: var(--gutter-sm);
  row-gap: var(--gutter-sm);
}

.Account-rss-create {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Account-rss-flows {
  margin-top: var(--gutter-lg);
}
.Account-rss-flow {
  background-color: var(--color-gray-darken);
  overflow: hidden;
  border-radius: var(--border-radius-base);
}
.Account-rss-flow-name {
  height: 50px;
  background-color: var(--color-primary-active);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-gray-lighten);
}
.Account-rss-flow-feed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--gutter-base);
}

.Account-rss-flow-no-feed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: var(--color-warning-default);
  font-weight: var(--font-weight-bold);
}
.Account-rss-flow-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Account-rss-flow-actions button {
  border-radius: 0;
}
.AccountInfoItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--gutter-sm);
  background-color: var(--color-gray-darken);
  border-radius: var(--border-radius-base);
}

.AccountInfoItem-info {
  display: flex;
  flex-direction: column;

}

.AccountInfoItem-label {
  color: var(--color-primary-lighten);
  font-weight: var(--font-weight-bold);
}

.AccountInfoItem-value {
  color: var(--color-primary-lighten);
}
.AccountInfoItem-extra {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Progress {
    width: 100%;
}

.Progress label {
    color: var(--color-gray-lighten);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--gutter-base);
}

.Progress-bar {
    
    overflow: hidden;
    border-radius: var(--border-radius-base);
    background-color: var(--color-gray-default);
    position: relative;
    height: 30px;
    text-align: center;
}

.Progress-bar-value {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    animation: progress-bar-stripes 2s linear infinite;
    background-image: -webkit-linear-gradient(45deg,rgba(var(--color-rgb-white),.25) 25%,transparent 25%,transparent 50%,rgba(var(--color-rgb-white),.25) 50%,rgba(var(--color-rgb-white),.25) 75%,transparent 75%,transparent);
    background-size: 40px 40px;
    transition: width 200ms ease-out;
    
}

.Progress-bar-value-success {
    background-color: var(--color-success-default);
}

.Progress-bar-value-warning {
    background-color: var(--color-warning-default);
}

.Progress-bar-value-danger {
    background-color: var(--color-danger-default);
}

.Progress-bar-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
}

@keyframes progress-bar-stripes {
    from  { background-position: 40px 0; }
    to    { background-position: 0 0; }
  }
.TorrentPrivate {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    grid-row-gap: var(--gutter-base);
    row-gap: var(--gutter-base);
}

.TorrentPrivate-empty {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    background-color: var(--color-warning-default);
    color: var(--color-primary-lighten);
    padding: var(--gutter-lg);
    border-radius: var(--border-radius-base);
}
.TorrentCompleteTypeChoice {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    border: 1px solid var(--color-primary-darken);
    border-radius: var(--border-radius-base);
    overflow: hidden;
}
.TorrentCompleteTypeChoice-item {
    cursor: pointer;
    padding: 0 var(--gutter-sm);
    color: var(--color-primary-lighten);
    background-color: var(--color-gray-darken);
    flex: 1 1;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.TorrentCompleteTypeChoice-item:not(:first-child) {
    border-left: 1px solid var(--color-primary-darken);
    
}

.TorrentCompleteTypeChoice-item > label {
    cursor: inherit;
}
.TorrentCompleteTypeChoice-item > input {
    cursor: inherit;
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}
.TorrentCompleteTypeChoice-item.selected {
    cursor: initial;
    background-color: var(--color-primary-active);
}


.TorrentLines-name {
    flex: 4 4;
}


.TorrentLine:hover {
    background-color: rgba(var(--color-rgb-primary-active), 0.3);
    cursor: pointer;
    border-radius: var(--border-radius-sm);
}
.TorrentLine-name {
    flex: 4 4;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
.TorrentLine td {
    line-height: 40px;
}
.TorrentHexagones {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    font-size: 15px;
    padding: 0;
}


@media (min-width:1601px) { /* <- 6-5  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 4.111%
    }
  }
  /*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
  @media (max-width: 1600px) and (min-width:1201px) { /* <- 5-4  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 5%
    }
  }
  
  @media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 6.2%
    }
  }
  
  @media (max-width: 991px) and (min-width:768px) { /* <- 3-2  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 8.1%
    }
  }
  
  @media (max-width: 767px) and (min-width:481px) { /* <- 2-1  hexagons per row */
    .TorrentHexagones {
      padding-bottom: 12.1%
    }
  }
  
  @media (max-width: 480px) {
    .TorrentHexagones {
      padding-bottom: 0;
    }
  }
.TorrentHexagone {
    position: relative;
    color: var(--color-white);
    visibility: hidden;
    outline: 1px solid transparent;
}
.TorrentHexagone::after {
    content: '';
    display: block;
    padding-bottom: 86.602%;
}
.TorrentHexagone-hexagone {
    position: absolute;
    width:96%;
    padding-bottom: 110.851%; /* =  width / sin(60) */
    margin: 0 2%;
    overflow: hidden;
    visibility: hidden;
    outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
    transform: rotate3d(0,0,1,-60deg) skewY(30deg);
    will-change: auto;
}

.TorrentHexagone-hexagone * {
    visibility: visible;
    outline: 1px solid transparent;
    will-change: auto;
}

.TorrentHexagone-container {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    color: var(--color-white);
    overflow: hidden;
    transform: skewY(-30deg) rotate3d(0,0,1,60deg);
    will-change: auto;
}
.TorrentHexagone-container::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--color-rgb-primary-default), 0.5);
    content: '';
}

.TorrentHexagone-image {
    opacity: 0.5;
    position: absolute;
    left: -100%;
    right: -100%;
    width: auto;
    height: 100%;
    margin: 0 auto;
    transform: rotate3d(0,0,0,0deg);
    will-change: auto;
}
.TorrentHexagone-top, .TorrentHexagone-bottom {
    z-index: 1;
    width: 100%;
    position: absolute;
    color: var(--color-white);
    background-color: rgba(var(--color-rgb-primary-default), 0.8);
    transition:          transform 200ms ease-in-out, opacity 200ms ease-out;
    opacity: 0;
    will-change: auto;
}
.TorrentHexagone-top {
    top: 0;
    bottom: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    font-size: var(--font-size-base);
    z-index: 1;
    box-shadow: 4px 3px 6px var(--color-primary-lighten);
    background-color: var(--color-primary-active);
    font-weight: bold;
    transform:translate3d(0,-100%,0);
    will-change: auto;
}
.TorrentHexagone-top::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.TorrentHexagone-bottom {
    top: 75%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform:translate3d(0,100%,0);
    will-change: auto;
}

.TorrentHexagone-info {
    z-index: 1;
    padding: var(--gutter-sm);
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.TorrentHexagone-info::before {
  position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: '';
	margin: auto 0;
	height: 0;
	background: linear-gradient(to bottom, rgba(var(--color-rgb-primary-active), 0.8), rgba(var(--color-rgb-primary-darken), 0.8) 50%, rgba(var(--color-rgb-primary-default), 0.8) 100%);
	transition: height 100ms ease-out;
  will-change: auto;
}

.TorrentHexagone-info-top {
  color: var(--color-primary-lighten);
  z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.TorrentHexagone-info-center {
    z-index: 1;
	flex: 1 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.TorrentHexagone-info-name {
  color: var(--color-primary-lighten);
  font-size: var(--font-size-base);
	word-break: break-all;
	width: 96%;
	margin: 0 auto;
	font-weight: var(--font-weight-bold);
}
.TorrentHexagone-info-bottom {
  color: var(--color-primary-lighten);
  z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.TorrentHexagone-info-ratio--success {
  color: var(--color-success-default);
}
.TorrentHexagone-info-ratio--primary {
  color: var(--color-primary-default);
}
.TorrentHexagone-info-ratio--warning {
  color: var(--color-warning-default);
}
.TorrentHexagone-info-ratio--danger {
  color: var(--color-danger-default);
}

/*
    HOVER EFFECT
*/
.TorrentHexagone-container:hover .TorrentHexagone-info::before {
	height: 100%;
}

.TorrentHexagone-container:hover .TorrentHexagone-top, .TorrentHexagone-container:focus .TorrentHexagone-top,
.TorrentHexagone-container:hover .TorrentHexagone-bottom, .TorrentHexagone-container:focus .TorrentHexagone-bottom {
    transform:translate3d(0,0,0);
    opacity: 1;
}
.TorrentHexagone-descriptif, .TorrentHexagone-not-tracker {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	color: var(--color-white);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: var(--gutter-sm);
}
.TorrentHexagone-descriptif:hover, .TorrentHexagone-descriptif:active, .TorrentHexagone-descriptif:active {
	color: var(--color-white);
}
.TorrentHexagone-privacy {
    height: auto;
	position: absolute;
	top: 0;
	bottom: 50%;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	border: 0;
}

.TorrentHexagone-remove {
    height: auto;
	position: absolute;
	top: 50%;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	border: 0;
	font-size: var(--font-size-base);
	color: var(--color-danger-default);
	background-color: transparent;
}


@media (min-width:1601px) { /* <- 6-5  hexagons per row */
    .TorrentHexagone {
      width: 16.666%; /* = 100 / 6 */
    }
    .TorrentHexagone:nth-child(11n+7){ /* first hexagon of even rows */
      margin-left:8.333%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  /*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
  @media (max-width: 1600px) and (min-width:1201px) { /* <- 5-4  hexagons per row */
    .TorrentHexagone {
      width: 20%; /* = 100 / 5 */
    }
    .TorrentHexagone:nth-child(9n+6){ /* first hexagon of even rows */
      margin-left:10%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
    .TorrentHexagone {
      width: 25%; /* = 100 / 4 */
    }
    .TorrentHexagone:nth-child(7n+5){ /* first hexagon of even rows */
      margin-left:12.5%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 991px) and (min-width:768px) { /* <- 3-2  hexagons per row */
    .TorrentHexagone {
      width: 33.333%; /* = 100 / 3 */
    }
    .TorrentHexagone:nth-child(5n+4){ /* first hexagon of even rows */
      margin-left:16.666%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 767px) and (min-width:481px) { /* <- 2-1  hexagons per row */
    .TorrentHexagone {
      width: 50%; /* = 100 / 3 */
    }
    .TorrentHexagone:nth-child(3n+3){ /* first hexagon of even rows */
      margin-left: 25%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 480px) {
    .TorrentHexagone {
      width: 100%;
      margin-bottom: 25%;
    }
  }
.TorrentCards {
    display: grid;
    grid-column-gap: var(--gutter-lg);
    column-gap: var(--gutter-lg);
    grid-row-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}
@media (min-width:1601px) { /* <- 6-5  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(5, 1fr);
  }
}
/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (max-width: 1600px) and (min-width:1201px) { /* <- 5-4  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) and (min-width:768px) { /* <- 3-2  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) { /* <- 2-1  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(1, 1fr);
  }
}

.TorrentCard {
    overflow: hidden;
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-color: rgba(var(--color-rgb-gray-darken), 0.5);
    border-radius: var(--border-radius-base);
    cursor: pointer;
    text-align: center;
}
.TorrentCard-label {
    justify-self: flex-start;
    align-self: baseline;
    color: var(--color-gray-lighten);
    font-size: var(--font-size-md);
    line-height: 30px;
}
.TorrentCard > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 var(--gutter-base);
}
.TorrentCard-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TorrentCard-item:not(:first-child) {
    border-top: 1px solid var(--color-gray-default);
}
.TorrentCard-name {
    border-top: none !important;
    height: 50px;
    line-height: 50px;
    background-color: rgba(var(--color-rgb-primary-default), 1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.TorrentCard-shared input {
    align-self: self-end;
}

.TorrentCard-remove {
    padding: 0;
}
.TorrentCard-remove > button {
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.TorrentCards {
    display: grid;
    grid-column-gap: var(--gutter-lg);
    column-gap: var(--gutter-lg);
    grid-row-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}
@media (min-width:1601px) { /* <- 6-5  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(5, 1fr);
  }
}
/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (max-width: 1600px) and (min-width:1201px) { /* <- 5-4  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) and (min-width:768px) { /* <- 3-2  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) { /* <- 2-1  hexagons per row */
  .TorrentCards {
    grid-template-columns: repeat(1, 1fr);
  }
}

.TorrentCard {
    position: relative;
    overflow: hidden;
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-color: rgba(var(--color-rgb-gray-darken), 0.5);
    border-radius: var(--border-radius-base);
    cursor: pointer;
    text-align: center;
}
.TorrentCard-progress {
    padding: 0 !important;
    z-index: 0;
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.TorrentCard-progress .Progress {
    
    width: 100%;
    flex: 1 1;
}
.TorrentCard .Progress-bar {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
}
.TorrentCard-label {
    justify-self: flex-start;
    align-self: baseline;
    color: var(--color-gray-lighten);
    font-size: var(--font-size-md);
    line-height: 30px;
}
.TorrentCard > * {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 var(--gutter-base);
}
.TorrentCard-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TorrentCard-item:not(:first-child) {
    border-top: 1px solid var(--color-gray-default);
}
.TorrentCard-name {
    border-top: none !important;
    height: 50px;
    line-height: 50px;
    background-color: rgba(var(--color-rgb-primary-default), 1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.TorrentCard-shared input {
    align-self: self-end;
}

.TorrentCard-remove {
    padding: 0;
}
.TorrentCard-remove > button {
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.TorrentLines {
    background-color: var(--color-gray-default);
    border-radius: var(--border-radius-base);
    color: var(--color-white);
    border-collapse: collapse;
    overflow: hidden;
}
.TorrentLines th {
    height: 50px;
    padding: 0 var(--gutter-base);
    text-align: left;
}

.TorrentLines-name {
    flex: 5 5;
}
.TorrentLine {
    position: relative;
    border-collapse: collapse;
    width: 100%;
    height: 40px;
    color: var(--color-white);
    background-color: var(--color-gray-darken);
}
.TorrentLine td {
    z-index: 1;
    border: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
    line-height: 40px;
}
.TorrentLine-name {
    flex: 5 5;
}
.TorrentLine-name a {
    color: var(--color-primary-lighten);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.TorrentLine-progress {
    padding: 0 !important;
    z-index: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.TorrentLine-progress .Progress {
    width: 100%;
    flex: 1 1;
}
.TorrentLine .Progress-bar {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
}
.Loader {
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(var(--color-rgb-primary-default), 0.8);
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms ease-in-out, visibility 0ms 200ms ease-in-out;
}
.Loader-visible {
    visibility: visible;
    opacity: 1;
}

.Loader-text {
    font-size: var(--heading-h3-font-size);
    font-weight: var(--font-weight-bold);
    -webkit-user-select: none;
            user-select: none;
}
.Loader-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .Loader-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .Loader-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .Loader-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .Loader-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.TorrentAdd {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    grid-row-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}
.TorrentAdd-forms {
    display: flex;
    flex-direction: row;
    grid-column-gap: var(--gutter-lg);
    column-gap: var(--gutter-lg);
    grid-row-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}

@media screen and (max-width: 768px) {
    .TorrentAdd-forms {
        flex-direction: column;
    }
}
.TorrentAdd .Card {
    flex: 1 1;
}

.TorrentAdd-url, .TorrentAdd-file {
    
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TorrentAdd-file-container {
    position: relative;
    flex: 1 1 100%;
}
.TorrentAdd-file-container > button {
    z-index: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.TorrentAdd-file > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.TorrentAdd-file-container > button {
    background-color: var(--color-primary-active);
}
.TorrentAdd-file-container > input {
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.Dropdown {
    position: relative;
    display: flex;
}

.Dropdown label {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--gutter-base);
    height: 50px;
    background-color: var(--color-primary-default);
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    border-radius: var(--border-radius-sm);
}
.Dropdown-content {
    z-index: 9999;
    margin-top: var(--gutter-sm);
    overflow: auto;
    min-width: 100%;
    max-height: 250px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--color-white);
    color: var(--color-black);
    border-radius: var(--border-radius-sm);
}
.ResultCards {
    padding: var(--gutter-base) 0;
    display: grid;
    grid-column-gap: var(--gutter-base);
    column-gap: var(--gutter-base);
    grid-row-gap: var(--gutter-base);
    row-gap: var(--gutter-base);
}
@media (min-width:1601px) { /* <- 6-5  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  /*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
  @media (max-width: 1600px) and (min-width:1201px) { /* <- 5-4  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 991px) and (min-width:768px) { /* <- 3-2  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 767px) { /* <- 2-1  hexagons per row */
    .ResultCards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
.ResultCard {
    overflow: hidden;
    border-radius: var(--border-radius-base);
    background-color: var(--color-gray-darken);
}
.ResultCard-name {
    padding: 0 var(--gutter-sm);
    display: block;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: var(--font-size-md);
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: var(--color-primary-active);
    color: var(--color-primary-lighten);
}
.ResultCard-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--gutter-sm);
    height: 24px;
}
.ResultCard-label {
    font-size: var(--font-size-sm);
    color: var(--color-gray-default);
}
.ResultCard-value {
    font-size: var(--font-size-md);
    color: var(--color-gray-active);
}
.ResultCard-add {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    justify-content: center;
    height: 40px !important;
}
.ResultLines {
    margin-top: var(--gutter-base);
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
}
.ResultLines-sort {
    flex: 0 0 24px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-active);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    
}
.ResultLines-sort--active {
    cursor: initial;
    background-color: var(--color-success-default);
}

.ResultLines-sort--asc {
    transform: rotate(90deg);
}

.ResultLines-sort--desc {
    transform: rotate(-90deg);
}

.ResultLines thead tr th {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: var(--gutter-sm);
    column-gap: var(--gutter-sm);
}

.ResultLines tbody tr {
    height: 40px;
}
.ResultLines tr:not(:first-child) {
    border-top: 1px solid var(--color-gray-default);
}
.ResultLines td , .ResultLines td a {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.ResultLines-name {
    flex: 4 4 !important;
}
.ResultLines-tracker {
    flex: 1.5 1.5 !important;
}


.ResultLine-name {
    flex: 4 4 !important;
}
.ResultLine-name a {
    display: block !important;
}
.ResultLine-tracker {
    flex: 1.5 1.5 !important;
}


.Search {
    padding: var(--gutter-sm);
    background-color: var(--color-gray-darken);
}
.Search-form {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .Search-form {
        flex-direction: column;
        align-items: stretch;
        grid-row-gap: var(--gutter-base);
        row-gap: var(--gutter-base);
    }
}

.Search-form .Dropdown {
    min-width: 250px;
}
.Search-trackers {
    display: flex;
    flex-direction: column;
}
.Search-trackers > button {
    border-radius: 0;
}
.Search-trackers > button:not(:first-child) {
    border-top: 1px solid var(--color-gray-darken);
}
.Search-form input {
    width: 100%;
}
.Search-progress {
    margin-top: var(--gutter-base);
}

.Admin {
    padding: 0 var(--gutter-lg);
}
.Role {
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-lighten);
    padding: var(--gutter-base);
    color: var(--color-black);
}
.Role-name {
    color: var(--color-primary-default);
}

.Role-limit {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Role-limit > * {
    flex: 1 1;
}

.Role-limit-name {
    font-weight: var(--font-weight-bold);
}

.Role-limit-type {
    font-weight: var(--font-weight-bold);
}

.Role-limit input {
    color: var(--color-black);
}
.Role-limit button {
    font-weight: var(--font-weight-bold);
}

.Role input::placeholder {
    color: var(--color-gray-default);
}

.Role-limits-create {
    display: flex;
    flex-direction: row;
}

.Role-limits-create > * {
    flex: 1 1;
}
.Roles-create {
    background-color: var(--color-gray-lighten);
    padding: var(--gutter-lg);
}

.Roles-create form {
    display: flex;
    flex-direction: column;
    grid-row-gap: var(--gutter-base);
    row-gap: var(--gutter-base);
}

.Roles-create input {
    color: var(--color-black);
}

.User select {
    width: 100%;
}

.User-validity {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column-gap: var(--gutter-base);
    column-gap: var(--gutter-base);
}
.Torrents-name {
    flex: 4 4;
}
.Torrent-name {
    flex: 4 4;
}
.TorrentDetail {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    grid-row-gap: var(--gutter-base);
    grid-row-gap: var(--gutter-base);
    row-gap: var(--gutter-base);
}
.TorrentDetail-archive {
  margin-bottom: var(--gutter-lg);
}
.TorrentDetail-path {
    cursor: pointer;
    margin-bottom: var(--gutter-base);
}

.TorrentDetail-name {
    flex: 4 4;
}

.TorrentDetail-download {
    flex: 2 2;
}

.TorrentDetail-cards {
    display: grid;
    grid-column-gap: var(--gutter-lg);
    column-gap: var(--gutter-lg);
    grid-row-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}

@media (max-width: 1200px) and (min-width:992px) { /* <- 4-3  hexagons per row */
  .TorrentDetail-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 991px) { /* <- 3-2  hexagons per row */
  .TorrentDetail-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

.FileLine {
    background-color: var(--color-gray-darken);
}

.FileLine td {
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    align-items: flex-start;
}

.FileLine-progress {
    z-index: 0;
    padding: 0 !important;
    z-index: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.FileLine.directory {
    cursor: pointer;
    color: var(--color-warning-default);
    background-color: rgba(var(--color-rgb-gray-darken), 0.6);
}

.FileLine.file {
    position: relative;
}

.FileLine-name {
    flex: 4 4;
}
.FileLine-download {
    flex: 2 2;
}
.FileLine-download a {
    display: flex;
}
.FileCard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-color: rgba(var(--color-rgb-gray-darken), 0.5);
    border-radius: var(--border-radius-base);
    cursor: pointer;
    text-align: center;
}
.FileCard-label {
    justify-self: flex-start;
    align-self: baseline;
    color: var(--color-gray-lighten);
    font-size: var(--font-size-md);
    line-height: 30px;
}
.FileCard > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 var(--gutter-base);
}
.FileCard-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FileCard-item:not(:first-child) {
    border-top: 1px solid var(--color-gray-default);
}
.FileCard-name {
    border-top: none !important;
    height: 50px;
    line-height: 50px;
    background-color: rgba(var(--color-rgb-primary-default), 1);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.FileCard-shared input {
    align-self: self-end;
}

.FileCard-download {
    height: 50px;
    padding: 0;
}
.FileCard-download > a {
    display: block;
    height: 100%;
}
.FileCard-download > a > button {
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.TorrentShared {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    grid-row-gap: var(--gutter-base);
    row-gap: var(--gutter-base);
}

.TorrentShared-empty {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    background-color: var(--color-warning-default);
    color: var(--color-primary-lighten);
    padding: var(--gutter-lg);
    border-radius: var(--border-radius-base);
}
.Register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-self: center;
}
.Register .Card {
  margin: 0 var(--gutter-base);
}
.Register-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Register-form > button {
  margin-top: var(--gutter-base);
}

.Register-error {
  margin-top: var(--gutter-base);
  color: var(--color-warning-default);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}
.TorrentStream {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    grid-row-gap: var(--gutter-lg);
    row-gap: var(--gutter-lg);
}

.TorrentStream .Card {
    flex: 1 1;
}

.TorrentStream video {
    width: 100%;
}
