.TorrentShared {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    row-gap: var(--gutter-base);
}

.TorrentShared-empty {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    background-color: var(--color-warning-default);
    color: var(--color-primary-lighten);
    padding: var(--gutter-lg);
    border-radius: var(--border-radius-base);
}