
.Header {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(var(--color-rgb-black), 0.9);
    height: var(--header-desktop-height);
    font-size: var(--heading-h5-font-size);
    color: white;
}
.Header > div {
    padding: 0 var(--gutter-lg);
    max-width: var(--grid-width-xl);
      margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: var(--gutter-lg);
}

.Header-logo {
    cursor: pointer;
    height: calc(var(--header-desktop-height) - calc(2 * var(--gutter-sm)));
}
  
.Header-auth, .Header-not-auth {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Header-auth {
    justify-content: space-between;
}
.Header-not-auth {
    justify-content: flex-end;
}
.Header-auth-links, .Header-auth-user, .Header-not-auth-links {
    display: flex;
    flex-direction: row;
    column-gap: var(--gutter-base);
    list-style-type:none;
    padding:0px;
    margin:0px;
}
  
.Header-auth-links li, .Header-auth-user li, .Header-not-auth-links li  {
    list-style:none;
    background-image:none;
    background-repeat:none;
    background-position:0; 
}
  
.Header-auth-links li a, .Header-auth-user li a, .Header-not-auth-links li a {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-primary-lighten);
    font-size: var(--heading-h6-font-size);
    font-weight: var(--font-weight-bold);
}

.Header-auth-links li a.active, .Header-auth-user li a.active, .Header-not-auth-links li a.active {
    color: var(--color-primary-active);
    font-weight: var(--font-weight-bold);
}
.Header-auth-links li a:hover, .Header-auth-user li a:hover, .Header-not-auth-links li a:hover {
    color: var(--color-primary-default);
}

.Header-menu-container {
    justify-content: space-between !important;
}
.Header-menu-backdrop {
    z-index: 999998;
    cursor: pointer;
    opacity: 0;
    position: fixed;
    top: var(--header-desktop-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--color-rgb-gray-darken), 0.8);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms linear, visibility 200ms linear;
}
.Header-menu-backdrop--visible {
    opacity: 1;
    visibility: visible;
}
.Header-menu-panel {
    z-index: 999999;
    position: fixed;
    top: var(--header-desktop-height);
    bottom: 0;
    left: 0;
    width: 300px;
    background-color: var(--color-primary-darken) !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between !important;
    padding: var(--gutter-lg);
    will-change: auto;
    transform: translate3d(-100%, 0, 0);
    transition: transform 200ms ease-out;
}

.Header-menu-panel--open {
    transform: translate3d(0, 0, 0);
}

.Header-menu-panel > ul {
    flex-direction: column !important;
    row-gap: var(--gutter-lg);
}

.Header-menu-icon {
    cursor: pointer;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--gutter-sm);
}
.Header-menu-icon {
    color: var(--color-primary-lighten);
}

.Header-menu-icon--visible {
    display: flex;
}

.Header-home {
    padding: 0 var(--gutter-base);
    display: flex;
    align-items: center;
    color: var(--color-primary-default) !important;
    text-decoration: none;
    height: 100%;
}