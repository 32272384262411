.TorrentStream {
    padding: 0 var(--gutter-lg);
    display: flex;
    flex-direction: column;
    row-gap: var(--gutter-lg);
}

.TorrentStream .Card {
    flex: 1 1 0;
}

.TorrentStream video {
    width: 100%;
}