.Card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--gutter-lg);
    color: var(--color-white);
    background-color: rgba(var(--color-rgb-primary-darken), 0.8);
    overflow: visible;
    border-radius: var(--border-radius-sm);
}
.Card--transparent {
    background-color: transparent;
}
.Card-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: var(--gutter-base);
}

@media screen and (max-width: 768px) {
    
    .Card-header {
        flex-direction: column;
    }

    .Card-actions {
        align-self: flex-end;
    }
}
.Card-header > label {
    display: block;
    width: 100%;
    margin-bottom: var(--gutter-lg);
    font-size: var(--heading-h4-font-size);
    font-weight: var(--font-weight-bold);
    flex: 1 1 0;
    word-break: break-all;
}
.Card-actions {
    flex: 0 0 auto;
}
.Card-content {
    overflow: visible;
}