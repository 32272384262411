.Loader {
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: var(--gutter-lg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(var(--color-rgb-primary-default), 0.8);
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms ease-in-out, visibility 0ms 200ms ease-in-out;
}
.Loader-visible {
    visibility: visible;
    opacity: 1;
}

.Loader-text {
    font-size: var(--heading-h3-font-size);
    font-weight: var(--font-weight-bold);
    user-select: none;
}
.Loader-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .Loader-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .Loader-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .Loader-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .Loader-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  