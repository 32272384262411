.Connection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-self: center;
  border-radius: var(--border-radius-base);
}
.Connection .Card {
  margin: 0 var(--gutter-base);
}
.Connection-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Connection-form > input {
  width: auto;
  margin-top: var(--gutter-base);
}
.Connection-form > button {
  margin-top: var(--gutter-2xl);
}