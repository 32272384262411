.Register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-self: center;
}
.Register .Card {
  margin: 0 var(--gutter-base);
}
.Register-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Register-form > button {
  margin-top: var(--gutter-base);
}

.Register-error {
  margin-top: var(--gutter-base);
  color: var(--color-warning-default);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}